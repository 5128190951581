<template>
  <div>
    <b-card
      :title="$t('Sub Accounts')"
    >
      <b-row class="justify-content-between">
        <b-col
          cols="2"
          class="mb-sm-1"
        >
          <b-form-group class="text-nowrap mb-0">

            <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
              style="min-width: 100px"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          md="6"
          sm="8"
          class="mb-1"
        >
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filterForSearch"
                type="search"
                placeholder="Type prefix or username to search"
                @keydown.enter="filterSearch"
              />
              <b-input-group-append>
                <b-button
                  @click="filterSearch"
                >
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            ref="table"
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="reloadData"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy.sync="loading"
            :filter="filter"
            :small="small"
          >
            <template #cell(ability)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                @click="getAbility(data.item.username)"
              >
                view
              </b-button>
            </template>
            <template #cell(edit)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
                @click="$router.push(`sub-account/edit?id=${data.item.username}`)"
              >
                <feather-icon icon="Edit3Icon" />
              </b-button>
            </template>

            <template #cell(delete)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
                @click="onDelete(data.item.username)"
              >
                <feather-icon icon="ArchiveIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="abilityDisble"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      title="สิทธิ์การเข้าใช้งาน"
      button-size="sm"
      size="sm"
      @ok="submitAbility"
    >
      <AbilityForm
        ref="abilityForm"
        :ability="ability"
        @onSubmit="onSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BModal,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AbilityForm from '@/views/components/AbilityForm'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import agentService from '@/service/agentService'

export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    AbilityForm
  },
  data() {
    return {
      small : true,
      abilityDisble: false,
      abilityUsername: '',
      ability: {},
      loading: false,
      perPage: 25,
      pageOptions: [1, 5, 10, 25, 50, 100, 150, 250, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'createAt',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterForSearch: '',
      // filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'index',
          label: 'ลำดับ',
          sortable: true,
        },
        {
          key: 'prefix',
          label: 'Prefix',
        },
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'nickName',
          label: 'NickName',
        },
        {
          key: 'ability',
          label: 'สิทธิ์การเข้าใช้งาน',
          class: 'text-nowrap text-center',
        },
        'edit',
        'delete',
        {
          key: 'isEnable',
          label: 'ระงับชั่วคราว',
          class: 'text-nowrap',
          formatter: v => (v ? 'NO' : 'YES'),
        },
        {
          key: 'createAt',
          label: 'Create At',
          class: 'text-nowrap',
        },
        {
          key: 'lastLoginAt',
          label: 'LastLogin',
          class: 'text-nowrap',
        },
        {
          key: 'lastLoginIp',
          label: 'LastIP',
          class: 'text-nowrap',
        },
      ],
    }
  },
  mounted() {
    if (this.$can('update', 'member') === false) {
      this.fields.splice(4, 3)
      this.small = false;
    }else{
      if (this.$store.state.app.userData.role === 'sub'){
        this.fields.splice(4, 3)
        this.small = false;
      }
    }
  },
  methods: {
    filterSearch() {
      this.filter = this.filterForSearch
    },
    async getAbility(id) {
      this.abilityUsername = id
      const res = await agentService.getAuthen(`subAccount/ability?username=${id}`)
      if (res.status === 200) {
        this.abilityDisble = true
        this.ability = res.data
      }
    },
    async reloadData() {
      this.loading = true
      const startRow = (this.currentPage - 1) * this.perPage
      const res = await agentService.getAuthen(`subAccount?filter=${this.filter}&limit=${this.perPage}&page=${startRow}&sortBy=${this.sortBy}&desc=${this.sortDesc}`)
      if (res.status === 200) {
        this.totalRows = res.data.count
        this.loading = false
        const { list } = res.data
        if (this.sortDesc === false) {
          let index = startRow + 1
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index += 1
          }
        } else {
          let index = res.data.count
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index -= 1
          }
        }
        return list
      }
      this.loading = false
      return []
    },
    submitAbility(e) {
      e.preventDefault()
      this.$refs.abilityForm.onSubmit()
    },
    onSubmit(v) {
      if (this.abilityUsername === '') {
        return
      }
      v = this.$refs.abilityForm.buildPermission(v);
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(async result => {
            if (result.value) {
              const res = await agentService.putAuthen(`subAccount/ability?username=${this.abilityUsername}`, v)
              if (res.status === 200) {
                this.abilityDisble = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'บันทึกข้อมูสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                  },
                })
              }
            }
          })
    },
    onDelete(username) {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการลบข้อมูลใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(async result => {
            if (result.value) {
              const res = await agentService.deleteAuthen(`subAccount?username=${username}`)
              if (res.status === 200) {
                this.$refs.table.refresh()

                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'บันทึกข้อมูสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                  },
                })
              }
            }
          })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

table {
  .sr-only {
    width: 0px !important;
  }
}
</style>

